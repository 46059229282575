<script setup lang="ts">
    import { inject } from 'vue';
    import { InjectionKeys } from 'o365-utils';
    import { useCheckoutButton } from 'o365-offline-components';
    import { type DataObject, type DataItemModel } from 'o365-dataobject';

    const props = defineProps<{
        row: DataItemModel,
        dataObject: DataObject,
        checkoutProcedure: string,
    }>();

    const isMobile = inject(InjectionKeys.mobileInitalized, false);

    const {
        checkoutAppID,
        enableCheckout,
        handleUpdateOfflineItems,
        getCheckedOutBySomeoneElse,
    } = useCheckoutButton(props.dataObject, props.checkoutProcedure);

    const getMobileButtonStyle = (row: DataItemModel): string => {
        return (row._handlingCheckoutRequest || getCheckedOutBySomeoneElse(row)) ? 'pointer-events:none;opacity:0.7;' : '';
    }

    const getDesktopButtonStyle = (row: DataItemModel): string => {
        return (enableCheckout.value && !getCheckedOutBySomeoneElse(row)) ? 'cursor:pointer;' : '';
    }

    const handleButtonClick = (row: DataItemModel): void => {
        handleUpdateOfflineItems(row, row.CheckedOutByMe ? 'CheckIn' : 'CheckOut');
    }
</script>

<template>
    <template v-if="isMobile">
        <div v-if="enableCheckout || row.CheckedOutBy_ID" class="d-flex align-items-center gap-2 border-top mt-2 pt-2">
            <template v-if="row._handlingCheckoutRequest ?? false">
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </template>

            <template v-else>
                <div v-if="enableCheckout"
                    @click.prevent="handleButtonClick(row)"
                    :style="getMobileButtonStyle(row)">
                    <template v-if="row.CheckedOutBy_ID && row.CheckedOutBy_AppID !== checkoutAppID">
                        <i class="bi bi-lock-fill text-secondary me-1"></i>
                        <span class="text-secondary">
                            {{ $t('Checked out by') }}
                            {{ row.CheckedOutBy }}
                            {{ $t('in another app') }}
                        </span>
                    </template>

                    <template v-else-if="row.CheckedOutBy_ID && !row.CheckedOutByMe">
                        <i class="bi bi-lock-fill text-danger me-1"></i>
                        <span class="text-danger">
                            {{ $t('Checked out by') }}
                            {{ row.CheckedOutBy }}
                        </span>
                    </template>

                    <template v-else-if="row.CheckedOutByMe">
                        <i class="bi bi-lock-fill text-success me-1"></i>
                        <span class="text-success">{{ $t('Checked out by me') }}</span>
                    </template>

                    <template v-else>
                        <i class="bi bi-unlock-fill text-primary me-1"></i>
                        <span class="text-primary">{{ $t('Tap to check out document') }}</span>
                    </template>
                </div>

                <template v-else>
                    <template v-if="row.CheckedOutBy_ID && !row.CheckedOutByMe">
                        <i class="bi bi-lock-fill text-danger me-1"></i>
                        <span class="text-danger">
                            {{ $t('Checked out by') }}
                            {{ row.CheckedOutBy }}
                        </span>
                    </template>

                    <template v-else-if="row.CheckedOutByMe">
                        <i class="bi bi-lock-fill text-success me-1"></i>
                        <span class="text-success">{{ $t('Checked out by me') }}</span>
                    </template>
                </template>
            </template>
        </div>
    </template>

    <template v-if="!isMobile">
        <div @click="handleButtonClick(row)"
            class="w-100"
            :style="getDesktopButtonStyle(row)"
            :disabled="(row._handlingCheckoutRequest || getCheckedOutBySomeoneElse(row)) ? true : null">
            <template v-if="enableCheckout">
                <template v-if="row._handlingCheckoutRequest ?? false">
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </template>

                <template v-else>
                    <i v-if="row.CheckedOutBy_AppID !== checkoutAppID && row.CheckedOutBy_ID"
                        class="bi bi-lock-fill text-secondary"
                        :title="`${$t('Checked out by')} ${row.CheckedOutBy} ${$t('in another app')}`">
                    </i>

                    <i v-else-if="getCheckedOutBySomeoneElse(row)"
                        class="bi bi-lock-fill text-danger"
                        :title="`${$t('Checked out by')} ${row.CheckedOutBy}`">
                    </i>

                    <i v-else-if="row.CheckedOutByMe"
                        class="bi bi-lock-fill text-success"
                        :title="$t('Checked out by me')">
                    </i>

                    <i v-else class="bi bi-unlock-fill text-primary"
                        :title="$t('Mark as available for offline')">
                    </i>
                </template>
            </template>

            <template v-else>
                <i v-if="getCheckedOutBySomeoneElse(row)"
                    class="bi bi-lock-fill text-danger"
                    :title="`${$t('Checked out by')} ${row.CheckedOutBy}`">
                </i>

                <i v-else-if="row.CheckedOutByMe"
                    class="bi bi-lock-fill text-success"
                    :title="$t('Checked out by me')">
                </i>
            </template>
        </div>
    </template>
</template>